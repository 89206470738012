"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("reflect-metadata");
var vue_class_component_1 = require("vue-class-component");
var container_1 = require("../dic/container");
function Container(target) {
    return vue_class_component_1.createDecorator(function (componentOptions, key) {
        if (typeof componentOptions.inject === "undefined") {
            componentOptions.inject = {};
        }
        componentOptions.inject[key] = { default: function () {
                return container_1.default.get(target);
            } };
    });
}
exports.Container = Container;
