var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "title",
          rawName: "v-title",
          value: _vm.$t("document.title"),
          expression: "$t('document.title')"
        }
      ],
      staticClass: "grey lighten-2"
    },
    [
      _c("locale-switcher-component"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c(
        "v-footer",
        { staticClass: "grey lighten-2", attrs: { height: "auto" } },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "", row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { "text-xs-center": "", xs12: "" } },
                [_c("legal-component")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }