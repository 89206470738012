var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      !_vm.user
        ? _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-center": "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-container",
            { attrs: { "grid-list-xl": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-md-center text-xs-center align-center",
                      attrs: { xs10: "", "offset-xs1": "" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/logo.png"),
                          alt: "Webrivage"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "blue--text font-weight-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("dashboard.header.management_registration")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        { staticClass: "header" },
                        [
                          _c(
                            "v-layout",
                            _vm._b(
                              { attrs: { "justify-center": "" } },
                              "v-layout",
                              _vm.columnBinding,
                              false
                            ),
                            [
                              _c(
                                "v-flex",
                                { attrs: { md4: "" } },
                                [
                                  _c("v-icon", { staticClass: "mr-1" }, [
                                    _vm._v("account_circle")
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("dashboard.header.login_as", {
                                          email: _vm.user.email
                                        })
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  style: { cursor: "pointer" },
                                  attrs: { md2: "" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "red--text mr-1" },
                                    [_vm._v("exit_to_app")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "red--text font-weight-bold",
                                      on: { click: _vm.logout }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("dashboard.header.logout")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pt-0",
                      attrs: { xs10: "", "offset-xs1": "" }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _vm._l(_vm.alerts, function(alert) {
                            return _c(
                              "v-alert",
                              {
                                key: alert.text,
                                staticClass: "text-xs-left font-weight-bold",
                                attrs: { value: true, type: alert.type }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(alert.text) +
                                    "\n          "
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            { staticClass: "black--text text-md-left" },
                            [
                              _c(
                                "p",
                                { staticClass: "subheading font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "dashboard.container.manage_preference"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("subscription-component", {
                                on: { alert: _vm.addAlert }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "subheading font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "dashboard.container.also_possibility"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("opposition-component", {
                                on: { alert: _vm.addAlert }
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "subheading font-weight-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "dashboard.container.details_consentements"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.container.details_consentements_text"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "potential-data-table md4",
                                  attrs: { md10: "", lg4: "", xs10: "" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "dashboard.container.potential_data"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("dashboard.container.list_data")
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("advertiser-component")
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }