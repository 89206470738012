"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _a;
require("babel-polyfill");
require("whatwg-fetch");
var vue_1 = require("vue");
var index_1 = require("./router/index");
var container_1 = require("./dic/container");
var config_1 = require("./dic/config");
var App_vue_1 = require("./App.vue");
var vee_validate_1 = require("vee-validate");
var store_1 = require("./store/store");
var Vuetify = require("vuetify");
var vue_flag_icon_1 = require("vue-flag-icon");
require("vuetify/dist/vuetify.min.css");
var index_2 = require("./i18n/index");
require("./error/index");
var vue_analytics_1 = require("vue-analytics");
vue_1.default.use(vue_flag_icon_1.default);
vue_1.default.use(vee_validate_1.default);
vue_1.default.use(Vuetify);
vue_1.default.use(vue_analytics_1.default, {
    id: "UA-27736731-5",
    router: index_1.default
});
vue_1.default.filter("country", function (code) {
    switch (code) {
        case "en":
        case "uk":
            return "gb";
        default:
            return code;
    }
});
vue_1.default.filter("upper", function (value) {
    return value.toUpperCase();
});
vue_1.default.directive("title", {
    inserted: function (_el, binding) { return (document.title = binding.value); },
    update: function (_el, binding) { return (document.title = binding.value); }
});
new vue_1.default({
    el: "#app",
    i18n: index_2.default,
    router: index_1.default,
    store: store_1.store,
    provide: (_a = {},
        _a[config_1.default.CONTAINER] = container_1.default,
        _a),
    template: "<AppComponent/>",
    components: { AppComponent: App_vue_1.default }
});
