"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
var qs = require("qs");
var config_1 = require("../dic/config");
var access_token_storage_1 = require("./login/access_token.storage");
var ClientService = (function () {
    function ClientService(accessTokenStorage, baseUrl) {
        this.accessTokenStorage = accessTokenStorage;
        this.host = baseUrl;
    }
    ClientService_1 = ClientService;
    ClientService.addInterceptor = function (fct) {
        this.interceptors.push(fct);
    };
    ClientService.prototype.get = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.request("GET", request)];
            });
        });
    };
    ClientService.prototype.post = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.request("POST", request)];
            });
        });
    };
    ClientService.prototype.put = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.request("PUT", request)];
            });
        });
    };
    ClientService.prototype.delete = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.request("DELETE", request)];
            });
        });
    };
    ClientService.prototype.patch = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.request("PATCH", request)];
            });
        });
    };
    ClientService.prototype.head = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.request("HEAD", request)];
            });
        });
    };
    ClientService.prototype.request = function (method, request) {
        return __awaiter(this, void 0, void 0, function () {
            var requestInit, headers_1, response, contentType, json, _a, _b, json, e_1, errorResponse_1, errorResponse, e_2, errorResponse;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 14, , 15]);
                        requestInit = { method: method };
                        if (["POST", "PUT", "PATCH"].indexOf(method) > -1 && request.data) {
                            requestInit.body = JSON.stringify(request.data);
                        }
                        if (["GET"].indexOf(method) > -1 && request.data) {
                            request.endpoint += "?" + qs.stringify(request.data);
                        }
                        headers_1 = new Headers();
                        headers_1.append("Content-Type", "application/json");
                        headers_1.append("Accept", "application/json");
                        if (request.credentials === true) {
                            headers_1.append("Authorization", "Bearer " + this.accessTokenStorage.getAccessToken());
                        }
                        if (request.headers) {
                            request.headers.forEach(function (value, key) {
                                headers_1.append(key, value);
                            });
                        }
                        requestInit.headers = headers_1;
                        return [4, fetch(this.host + request.endpoint, requestInit)];
                    case 1:
                        response = _c.sent();
                        contentType = (response.headers.get("Content-Type") || "").split(";")[0];
                        if (!response.ok) return [3, 8];
                        if (!(method === "HEAD" || response.status === 204)) return [3, 2];
                        _a = undefined;
                        return [3, 7];
                    case 2:
                        if (!(contentType === "application/json")) return [3, 4];
                        return [4, response.json()];
                    case 3:
                        _b = _c.sent();
                        return [3, 6];
                    case 4: return [4, response.text()];
                    case 5:
                        _b = _c.sent();
                        _c.label = 6;
                    case 6:
                        _a = _b;
                        _c.label = 7;
                    case 7:
                        json = _a;
                        return [2, { status: response.status, data: json, headers: response.headers, request: { headers: headers_1 } }];
                    case 8:
                        if (!(response.status >= 400 && response.status < 500)) return [3, 13];
                        json = void 0;
                        _c.label = 9;
                    case 9:
                        _c.trys.push([9, 11, , 12]);
                        return [4, response.json()];
                    case 10:
                        json = _c.sent();
                        return [3, 12];
                    case 11:
                        e_1 = _c.sent();
                        return [3, 12];
                    case 12:
                        errorResponse_1 = { status: response.status, data: json, headers: response.headers };
                        this.callInterceptors(request, errorResponse_1);
                        return [2, Promise.reject(errorResponse_1)];
                    case 13:
                        errorResponse = { status: response.status, headers: response.headers };
                        this.callInterceptors(request, errorResponse);
                        return [2, Promise.reject(errorResponse)];
                    case 14:
                        e_2 = _c.sent();
                        errorResponse = { error: e_2 };
                        this.callInterceptors(request, errorResponse);
                        return [2, Promise.reject(errorResponse)];
                    case 15: return [2];
                }
            });
        });
    };
    ClientService.prototype.callInterceptors = function (request, response) {
        ClientService_1.interceptors.forEach(function (fct) {
            fct.apply(null, [request, response]);
        });
    };
    var ClientService_1;
    ClientService.interceptors = [];
    ClientService = ClientService_1 = __decorate([
        inversify_1.injectable(),
        __param(0, inversify_1.inject(config_1.default.ACCESS_TOKEN_STORAGE)),
        __param(1, inversify_1.inject("api.base_url")),
        __metadata("design:paramtypes", [access_token_storage_1.AccessTokenStorage, String])
    ], ClientService);
    return ClientService;
}());
exports.ClientService = ClientService;
