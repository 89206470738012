var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md4: "" } },
                [
                  !_vm.emailSend
                    ? _c(
                        "v-alert",
                        { attrs: { value: _vm.loginError, type: "error" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("errors.form.invalid_auth")) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "elevation-23 pa-4" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-md-center text-xs-center" },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/logo.png"),
                              alt: ""
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "blue--text font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("login.header.management_registration")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.emailSend
                            ? _c(
                                "div",
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.$t("login.header.use_email"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return _vm.onSubmit($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|email",
                                            expression: "'required|email'"
                                          }
                                        ],
                                        attrs: {
                                          "prepend-icon": "person",
                                          label: "E-mail",
                                          name: "email",
                                          "error-messages": _vm.errors.first(
                                            "email"
                                          ),
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.user.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "email", $$v)
                                          },
                                          expression: "user.email"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "vue-recaptcha",
                                        {
                                          ref: "invisibleRecaptcha",
                                          attrs: {
                                            size: "invisible",
                                            sitekey: _vm.captchaSiteKey
                                          },
                                          on: {
                                            verify: _vm.onVerify,
                                            expired: _vm.onCaptchaExpired
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                clarge: "",
                                                color: "primary my-3",
                                                loading: _vm.loader,
                                                disabled: _vm.loader
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.onSubmit($event)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("login.header.send")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "green" } },
                                    [_vm._v("done")]
                                  ),
                                  _vm._v(" "),
                                  _c("p", {
                                    staticClass: "green--text",
                                    staticStyle: { "margin-bottom": "0px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("login.header.email_send", {
                                          email: _vm.user.email
                                        })
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("p", {
                                    staticClass: "green--text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("login.header.click_link")
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.$t("login.header.verify_spam"))
                                    )
                                  ])
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }