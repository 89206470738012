"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var log = function (error) {
    console.error(error);
};
vue_1.default.config.errorHandler = function (err, _vm, info) {
    log(new Error("VueJS Error : " + err + " -  info : " + info));
};
vue_1.default.config.warnHandler = function (err, _vm, info) {
    log(new Error("VueJS Warning : " + err + " -  info : " + info));
};
window.addEventListener("unhandledrejection", function (e) {
    log(new Error("Unhandled promise rejection : " + e.reason));
});
window.addEventListener("rejectionhandled", function (e) {
    log(new Error("Rejection handled : " + e.reason));
});
