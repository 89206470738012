"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SERVICE_IDENTIFIER = {
    LOCAL_STORAGE: Symbol("LocalStorage"),
    ACCESS_TOKEN_STORAGE: Symbol("AccessTokenStorage"),
    CONTAINER: Symbol("Container"),
    SERVICE_CLIENT: Symbol("ClientService"),
    SERVICE_LOCALE: Symbol("LocaleService"),
    I18N_FACTORY: Symbol("I18nFactory"),
    REPOSITORY_USER: Symbol("UserRepository"),
    REPOSITORY_ADVERTISER: Symbol("AdvertiserRepository"),
    REPOSITORY_SUBSCRIPTION: Symbol("SubscriptionRepository"),
    REPOSITORY_OPPOSITION: Symbol("OppositionRepository")
};
exports.default = SERVICE_IDENTIFIER;
