"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
var config_1 = require("../../dic/config");
var AccessTokenStorage = (function () {
    function AccessTokenStorage(localStorage) {
        this.localStorage = localStorage;
    }
    AccessTokenStorage.prototype.setAccessToken = function (accessToken) {
        this.localStorage.setItem("access_token", accessToken);
    };
    AccessTokenStorage.prototype.getAccessToken = function () {
        return this.localStorage.getItem("access_token");
    };
    AccessTokenStorage.prototype.hasAccessToken = function () {
        return this.localStorage.getItem("access_token") !== null;
    };
    AccessTokenStorage.prototype.removeAccessToken = function () {
        this.localStorage.removeItem("access_token");
    };
    AccessTokenStorage = __decorate([
        inversify_1.injectable(),
        __param(0, inversify_1.inject(config_1.default.LOCAL_STORAGE)),
        __metadata("design:paramtypes", [Storage])
    ], AccessTokenStorage);
    return AccessTokenStorage;
}());
exports.AccessTokenStorage = AccessTokenStorage;
