"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var opposition_repository_1 = require("../../repositories/opposition.repository");
var moment = require("moment");
var OppositionComponent = (function (_super) {
    __extends(OppositionComponent, _super);
    function OppositionComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loader = false;
        _this.dialog = false;
        _this.isError = false;
        return _this;
    }
    OppositionComponent.prototype.sendAlert = function () {
        var momentDate = moment(this.opposition.createdAt);
        this.$emit("alert", {
            text: this.$tc("dashboard.opposition.confirm", undefined, { date: momentDate.format("L"), time: momentDate.format("LT") }),
            type: "success"
        });
    };
    OppositionComponent.prototype.mounted = function () {
        var _this = this;
        this.oppositionRepository.getOpposition()
            .then(function (opposition) {
            _this.opposition = opposition;
            _this.isOpposed = true;
            _this.sendAlert();
        }).catch(function (e) {
            if (e.status === 404) {
                _this.isOpposed = false;
            }
            else {
                _this.isError = true;
                _this.$emit("alert", {
                    text: _this.$tc("dashboard.opposition.error"),
                    type: "error"
                });
            }
        });
    };
    Object.defineProperty(OppositionComponent.prototype, "columnBinding", {
        get: function () {
            if (this.$vuetify.breakpoint.smAndDown) {
                return { column: true };
            }
            return {};
        },
        enumerable: true,
        configurable: true
    });
    OppositionComponent.prototype.opposite = function () {
        var _this = this;
        this.loader = true;
        this.oppositionRepository.createOpposition()
            .then(function () {
            _this.loader = false;
            _this.dialog = false;
            _this.isOpposed = true;
            _this.opposition = { createdAt: new Date() };
            _this.sendAlert();
            _this.$ga.event({
                eventCategory: 'opposition',
                eventAction: 'click',
                eventLabel: 'confirmation opposition',
            });
        })
            .catch(function () {
            _this.loader = false;
            _this.dialog = false;
        });
    };
    OppositionComponent.prototype.data = function () {
        return {
            isOpposed: this.isOpposed
        };
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_OPPOSITION),
        __metadata("design:type", opposition_repository_1.OppositionRepository)
    ], OppositionComponent.prototype, "oppositionRepository", void 0);
    OppositionComponent = __decorate([
        vue_class_component_1.default
    ], OppositionComponent);
    return OppositionComponent;
}(vue_1.default));
exports.default = OppositionComponent;
