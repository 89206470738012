"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_recaptcha_1 = require("vue-recaptcha");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var user_repository_1 = require("../../repositories/user.repository");
var IdentifierComponent = (function (_super) {
    __extends(IdentifierComponent, _super);
    function IdentifierComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.emailSend = false;
        _this.loader = false;
        _this.user = {
            email: "",
            recaptchaResponse: ""
        };
        return _this;
    }
    IdentifierComponent.prototype.onVerify = function (response) {
        var _this = this;
        var callbackSuccess = function () {
            _this.emailSend = true;
            _this.loader = false;
        };
        var callbackFail = function (response) {
            _this.loader = false;
            switch (response.status) {
                case 401:
                    _this.$refs.invisibleRecaptcha.reset();
                    _this.user.recaptchaResponse = "";
                    _this.$validator.errors.add({
                        field: "email",
                        msg: _this.$tc("errors.form.email_dont_exist")
                    });
                    break;
                case 403:
                    _this.$validator.errors.add({
                        field: "email",
                        msg: _this.$tc("errors.form.invalid_captcha")
                    });
                    break;
                default:
                    _this.$validator.errors.add({
                        field: "email",
                        msg: _this.$tc("errors.default_error")
                    });
            }
        };
        this.$validator.validateAll()
            .then(function (result) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (result) {
                    this.user.recaptchaResponse = response;
                    this.userRepository
                        .getIdentifier(this.user.email, this.user.recaptchaResponse, this.$i18n.locale)
                        .then(callbackSuccess)
                        .catch(callbackFail);
                }
                return [2];
            });
        }); });
    };
    IdentifierComponent.prototype.onCaptchaExpired = function () {
        this.user.recaptchaResponse = "";
    };
    IdentifierComponent.prototype.onSubmit = function () {
        var _this = this;
        this.$validator.validateAll()
            .then(function (result) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (result) {
                    this.$refs.invisibleRecaptcha.reset();
                    this.loader = true;
                    this.$refs.invisibleRecaptcha.execute();
                }
                return [2];
            });
        }); });
    };
    IdentifierComponent.prototype.created = function () {
        if (this.$route.query.loginError) {
            this.loginError = this.$route.query.loginError;
        }
    };
    IdentifierComponent.prototype.data = function () {
        return {
            loginError: this.loginError
        };
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], IdentifierComponent.prototype, "userRepository", void 0);
    __decorate([
        decorators_1.Container("captcha.site_key"),
        __metadata("design:type", String)
    ], IdentifierComponent.prototype, "captchaSiteKey", void 0);
    IdentifierComponent = __decorate([
        vue_class_component_1.default({
            components: { VueRecaptcha: vue_recaptcha_1.default }
        })
    ], IdentifierComponent);
    return IdentifierComponent;
}(vue_1.default));
exports.default = IdentifierComponent;
