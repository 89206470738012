"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
var config_1 = require("../../dic/config");
var locale_service_1 = require("./locale.service");
var i18n_service_1 = require("./i18n.service");
var catalog = require("catalog");
var vue_1 = require("vue");
var I18nFactory = (function () {
    function I18nFactory(localeService) {
        this.localeService = localeService;
    }
    I18nFactory.prototype.create = function () {
        vue_1.default.use(i18n_service_1.I18n);
        var dateTimeFormats = {
            short: {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            }
        };
        return new i18n_service_1.I18n(this.localeService, {
            messages: catalog,
            dateTimeFormats: {
                fr: dateTimeFormats,
                en: dateTimeFormats,
                es: dateTimeFormats,
                it: dateTimeFormats
            }
        });
    };
    I18nFactory = __decorate([
        inversify_1.injectable(),
        __param(0, inversify_1.inject(config_1.default.SERVICE_LOCALE)),
        __metadata("design:paramtypes", [locale_service_1.LocaleService])
    ], I18nFactory);
    return I18nFactory;
}());
exports.I18nFactory = I18nFactory;
