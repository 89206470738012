"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_property_decorator_1 = require("vue-property-decorator");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var subscription_repository_1 = require("../../repositories/subscription.repository");
var SubscriptionComponent = (function (_super) {
    __extends(SubscriptionComponent, _super);
    function SubscriptionComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isError = false;
        return _this;
    }
    SubscriptionComponent.prototype.mounted = function () {
        var _this = this;
        this.subscriptionRepository.isSubscribed()
            .then(function (isSubscribed) { return (_this.isSubscribed = isSubscribed); })
            .catch(function () {
            _this.isError = true;
            _this.$emit("alert", {
                text: _this.$tc("dashboard.subscription.error"),
                type: "error"
            });
        });
    };
    SubscriptionComponent.prototype.deleteSubscription = function (val, oldVal) {
        var _this = this;
        if (val === false && oldVal === true) {
            this.subscriptionRepository.deleteSubscriptions()
                .then(function () {
                (_this.$ga.event({
                    eventCategory: 'subscriptions',
                    eventAction: 'click',
                    eventLabel: 'delete subscriptions',
                }));
            });
        }
    };
    Object.defineProperty(SubscriptionComponent.prototype, "columnBinding", {
        get: function () {
            if (this.$vuetify.breakpoint.smAndDown) {
                return { column: true };
            }
            return {};
        },
        enumerable: true,
        configurable: true
    });
    SubscriptionComponent.prototype.data = function () {
        return {
            isSubscribed: this.isSubscribed,
        };
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_SUBSCRIPTION),
        __metadata("design:type", subscription_repository_1.SubscriptionRepository)
    ], SubscriptionComponent.prototype, "subscriptionRepository", void 0);
    __decorate([
        vue_property_decorator_1.Watch("isSubscribed"),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean, Boolean]),
        __metadata("design:returntype", void 0)
    ], SubscriptionComponent.prototype, "deleteSubscription", null);
    SubscriptionComponent = __decorate([
        vue_class_component_1.default
    ], SubscriptionComponent);
    return SubscriptionComponent;
}(vue_1.default));
exports.default = SubscriptionComponent;
