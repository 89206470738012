"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var container_1 = require("../dic/container");
var config_1 = require("../dic/config");
var client_service_1 = require("../services/client.service");
var Identifier_vue_1 = require("../components/login/Identifier.vue");
var Connect_vue_1 = require("../components/login/Connect.vue");
var Dashboard_vue_1 = require("../components/Dashboard.vue");
var store_1 = require("../store/store");
vue_1.default.use(vue_router_1.default);
var meta = { secure: true };
var routerOptions = {
    routes: [
        {
            path: "/login",
            name: "login",
            component: Identifier_vue_1.default,
            meta: { secure: false },
        },
        {
            path: "/login/:token",
            name: "login_as",
            component: Connect_vue_1.default,
            meta: { secure: false }
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard_vue_1.default,
            meta: meta
        },
        {
            path: "/*",
            name: "index",
            redirect: { name: "dashboard" },
            meta: meta
        }
    ],
    scrollBehavior: function () {
        return { x: 0, y: 0 };
    }
};
var router = new vue_router_1.default(routerOptions);
router.beforeEach(function (to, _from, next) {
    var tokenStorage = container_1.default.get(config_1.default.ACCESS_TOKEN_STORAGE);
    if (to.meta.secure === true && tokenStorage.hasAccessToken() === false) {
        next({ name: "login" });
    }
    next();
});
client_service_1.ClientService.addInterceptor(function (response) {
    if (response.status === 401) {
        store_1.store.dispatch("login/logout");
        router.push({ name: "login", query: { loginError: "true" } });
    }
});
exports.default = router;
