var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-data-table",
    {
      staticClass: "elevation-1",
      attrs: {
        headers: _vm.headers,
        items: _vm.advertisers,
        loading: _vm.loading,
        "rows-per-page-items": _vm.rowsPerPageItems,
        pagination: _vm.pagination,
        "no-data-text": _vm.noDataText
      },
      on: {
        "update:pagination": function($event) {
          _vm.pagination = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "items",
          fn: function(props) {
            return [
              _c(
                "td",
                [
                  _vm._v(_vm._s(props.item.name)),
                  _vm.$i18n.locale !== props.item.market
                    ? [
                        _vm._v(
                          " ( " +
                            _vm._s(_vm._f("upper")(props.item.market)) +
                            " )"
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("td", [
                props.item.privacyUrl || props.item.lastCampaignPrivacyUrl
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href:
                            props.item.privacyUrl ||
                            props.item.lastCampaignPrivacyUrl,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("dashboard.container.link")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          }
        }
      ])
    },
    [
      _c("v-progress-linear", {
        attrs: { slot: "progress", color: "blue", indeterminate: "" },
        slot: "progress"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }