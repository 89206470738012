"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var Subscription_vue_1 = require("./dashboard/Subscription.vue");
var Opposition_vue_1 = require("./dashboard/Opposition.vue");
var Advertiser_vue_1 = require("./dashboard/Advertiser.vue");
var decorators_1 = require("../decorator/decorators");
var config_1 = require("../dic/config");
var user_repository_1 = require("../repositories/user.repository");
var vuex_class_1 = require("vuex-class");
var loginModule = vuex_class_1.namespace("login");
var DashboardComponent = (function (_super) {
    __extends(DashboardComponent, _super);
    function DashboardComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.alerts = [];
        return _this;
    }
    DashboardComponent.prototype.logout = function () {
        this.ActionRemoveToken();
        this.$router.push({ name: "login" });
        this.$ga.event({
            eventCategory: 'logout',
            eventAction: 'click',
            eventLabel: 'logout dashboard',
        });
    };
    DashboardComponent.prototype.mounted = function () {
        var _this = this;
        this.userRepository
            .me()
            .then(function (user) { return (_this.user = user); })
            .catch(function () { });
    };
    Object.defineProperty(DashboardComponent.prototype, "columnBinding", {
        get: function () {
            if (this.$vuetify.breakpoint.mdAndDown) {
                return { column: true };
            }
            return {};
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.addAlert = function (alert) {
        this.alerts.push(alert);
    };
    DashboardComponent.prototype.data = function () {
        return {
            user: this.user
        };
    };
    __decorate([
        loginModule.Action("logout"),
        __metadata("design:type", Function)
    ], DashboardComponent.prototype, "ActionRemoveToken", void 0);
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], DashboardComponent.prototype, "userRepository", void 0);
    DashboardComponent = __decorate([
        vue_class_component_1.default({
            components: { SubscriptionComponent: Subscription_vue_1.default, OppositionComponent: Opposition_vue_1.default, AdvertiserComponent: Advertiser_vue_1.default }
        })
    ], DashboardComponent);
    return DashboardComponent;
}(vue_1.default));
exports.default = DashboardComponent;
