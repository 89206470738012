"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vuex_1 = require("vuex");
var login_store_1 = require("./login.store");
vue_1.default.use(vuex_1.default);
exports.store = new vuex_1.default.Store({
    modules: {
        login: login_store_1.loginModule
    }
});
