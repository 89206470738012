"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var container_1 = require("../dic/container");
var config_1 = require("../dic/config");
var accessTokenStorage = container_1.default.get(config_1.default.ACCESS_TOKEN_STORAGE);
exports.loginModule = {
    namespaced: true,
    state: {
        accessToken: accessTokenStorage.getAccessToken(),
    },
    mutations: {
        setToken: function (state, token) {
            state.accessToken = token;
        },
        removeToken: function (state) {
            state.accessToken = null;
        }
    },
    actions: {
        login: function (context, token) {
            accessTokenStorage.setAccessToken(token);
            context.commit("setToken", token);
        },
        logout: function (context) {
            accessTokenStorage.removeAccessToken();
            context.commit("removeToken");
        }
    }
};
