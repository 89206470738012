var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    _vm._b({ attrs: { row: "" } }, "v-layout", _vm.columnBinding, false),
    [
      _c("v-flex", { attrs: { md3: "", sm5: "", xs12: "" } }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("dashboard.container.receive_offers_webrivage")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { md3: "", sm5: "", xs12: "" } },
        [
          !_vm.isError
            ? [
                _vm.isSubscribed === undefined
                  ? _c("v-progress-circular", {
                      attrs: { size: 20, color: "info", indeterminate: "" }
                    })
                  : _c(
                      "v-radio-group",
                      {
                        staticClass: "pt-0 mt-0 text-md-center",
                        attrs: { row: "", height: "30" },
                        model: {
                          value: _vm.isSubscribed,
                          callback: function($$v) {
                            _vm.isSubscribed = $$v
                          },
                          expression: "isSubscribed"
                        }
                      },
                      [
                        _c("v-radio", {
                          attrs: {
                            label: _vm.$t("dashboard.container.radio_yes"),
                            value: true,
                            disabled: _vm.isSubscribed === false
                          }
                        }),
                        _vm._v(" "),
                        _c("v-radio", {
                          attrs: {
                            label: _vm.$t("dashboard.container.radio_no"),
                            value: false
                          }
                        })
                      ],
                      1
                    )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }