"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
var client_service_1 = require("../services/client.service");
var config_1 = require("./config");
var locale_service_1 = require("../services/i18n/locale.service");
var i18n_factory_1 = require("../services/i18n/i18n.factory");
var access_token_storage_1 = require("../services/login/access_token.storage");
var user_repository_1 = require("../repositories/user.repository");
var advertiser_repository_1 = require("../repositories/advertiser.repository");
var subscription_repository_1 = require("../repositories/subscription.repository");
var opposition_repository_1 = require("../repositories/opposition.repository");
var container = new inversify_1.Container();
container.bind(config_1.default.SERVICE_CLIENT).to(client_service_1.ClientService);
container.bind(config_1.default.SERVICE_LOCALE).to(locale_service_1.LocaleService);
container.bind(config_1.default.REPOSITORY_USER).to(user_repository_1.UserRepository);
container.bind(config_1.default.REPOSITORY_ADVERTISER).to(advertiser_repository_1.AdvertiserRepository);
container.bind(config_1.default.REPOSITORY_SUBSCRIPTION).to(subscription_repository_1.SubscriptionRepository);
container.bind(config_1.default.REPOSITORY_OPPOSITION).to(opposition_repository_1.OppositionRepository);
container.bind("api.base_url").toConstantValue(__API_BASE_URL__);
container.bind("environment").toConstantValue(__ENVIRONMENT__);
container.bind("captcha.site_key").toConstantValue(__CAPTCHA_SITE_KEY__);
container.bind(config_1.default.I18N_FACTORY).to(i18n_factory_1.I18nFactory);
container.bind(config_1.default.LOCAL_STORAGE).toConstantValue(window.localStorage);
container.bind(config_1.default.ACCESS_TOKEN_STORAGE).to(access_token_storage_1.AccessTokenStorage);
exports.default = container;
