var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "80%" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "span",
        { attrs: { slot: "activator", dark: "" }, slot: "activator" },
        [
          _vm._v("\n    © " + _vm._s(_vm.year) + " "),
          _c("strong", [_vm._v("Webrivage")]),
          _vm._v(" - "),
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("footer.privacy")))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline grey lighten-2",
              attrs: { "primary-title": "" }
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("footer.privacy")) + "\n    ")]
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _c("div", {
              attrs: { id: "our-privacy" },
              domProps: { innerHTML: _vm._s(_vm.$t("legal.content")) }
            })
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("legal.close")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }