var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    _vm._b({ attrs: { row: "" } }, "v-layout", _vm.columnBinding, false),
    [
      _c(
        "v-flex",
        { staticClass: "align-center", attrs: { md5: "", sm5: "", xs12: "" } },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("dashboard.container.opposition_data")) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticClass: "pt-0", attrs: { md3: "", sm5: "", xs12: "" } },
        [
          !_vm.isError
            ? [
                _vm.isOpposed === undefined
                  ? _c("v-progress-circular", {
                      attrs: { size: 20, color: "info", indeterminate: "" }
                    })
                  : _c(
                      "v-dialog",
                      {
                        attrs: {
                          "max-width": "650",
                          disabled: _vm.isOpposed === true
                        },
                        model: {
                          value: _vm.dialog,
                          callback: function($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog"
                        }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              slot: "activator",
                              color: "info",
                              disabled: _vm.isOpposed === true
                            },
                            slot: "activator"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("dashboard.container.erase_data")
                                ) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "headline" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("dashboard.modal.confirm_opposition")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-card-text", [
                              _vm._v(
                                _vm._s(_vm.$t("dashboard.modal.opposition"))
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", flat: "flat" },
                                    on: {
                                      click: function($event) {
                                        _vm.dialog = false
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("dashboard.modal.disagree")
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "error",
                                      loading: _vm.loader
                                    },
                                    on: { click: _vm.opposite }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("dashboard.modal.agree")
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }